<script setup lang="ts">
const head: any = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

const htmlAttrs = computed(() => head.value.htmlAttrs!)

const route = useRoute()

const refCode: any = useCookie('refCode')

refCode.value = refCode.value || route?.query?.ref
</script>

<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir" class="layout1">
    <Body>
      <Layout1Header />

      <div class="relative pt-32 pb-16">
        <slot />
      </div>

      <Layout1Footer />

      <GroupButtonFloat />

      <CookieSection />
    </Body>
  </Html>
</template>
