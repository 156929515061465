<script setup lang="ts">
const { t } = useI18n()
// const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section23)

const route = useRoute()

const keyword = ref<any>(route?.query?.keyword ?? '')

async function onSelect() {
  if (!keyword.value)
    return false

  await navigateTo({ path: data.value?.href, query: { keyword: keyword.value } }, { external: true })
}
</script>

<template>
  <!-- Start block 23 -->
  <section v-if="data?.status" class="bg-white">
    <div class="mx-auto max-w-screen-lg px-2 pt-10 mb-10 sm:mb-20">
      <div class="container mx-auto">
        <div class="flex items-center justify-center">
          <div class="flex w-full mx-10 rounded-lg border-2 p-1">
            <UInput
              v-model="keyword"
              type="search"
              name="keywordproduct"
              color="gray"
              variant="none"
              :placeholder="t('search')"
              class="w-full h-10 border-none bg-transparent text-gray-600 outline-none focus:outline-none text-lg"
              size="xl"
              :ui="{
                label: {
                  base: 'text-lg',
                },
              }"
              @keyup.enter="onSelect"
            />

            <UButton
              aria-label="search-product"
              icon="i-heroicons-magnifying-glass-20-solid"
              size="xl"
              color="black"
              square
              variant="solid"
              @click="onSelect"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 23 -->
</template>
