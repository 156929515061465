<script setup lang="ts">
const { locale }: any = useI18n()

const sitedata: any = useSiteData()

const section1 = computed(() => sitedata.value?.home_data?.section1)
</script>

<template>
  <!-- Start block -->
  <section v-if="section1?.status" class="text-gray-800">
    <div class="grid max-w-screen-xl px-4 pb-8 mx-auto lg:gap-8 xl:gap-3 lg:py-32 lg:grid-cols-12">
      <div class="mr-auto place-self-center lg:col-span-6">
        <h1 class="max-w-2xl mb-8 text-5xl sm:text-4xl md:text-5xl xl:text-6xl font-extrabold leading-none tracking-tight text-green-600">
          {{ section1?.text1 }}
        </h1>
        <div v-if="section1?.list1?.length" class="mb-6">
          <p
            v-for="(item, index) in section1?.list1"
            :key="`wZeQGB${index}`"
            class="flex items-center jusify-start mb-3 text-2xl sm:text-xl"
          >
            {{ item }}
          </p>
        </div>

        <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
          <NuxtLinkLocale
            :to="section1?.button1?.href ?? '/#'"
            :hreflang="locale"
            class="inline-flex items-center justify-center w-full px-5 py-3 text-2xl sm:text-xl font-medium text-center text-white border border-gray-200 rounded-lg sm:w-auto bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-gray-100"
          >
            <svg class="w-4 h-4 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
              <path fill-rule="evenodd" d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" clip-rule="evenodd" />
              <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
            </svg>
            {{ section1?.button1?.text }}
          </NuxtLinkLocale>

          <NuxtLinkLocale
            :to="section1?.button2?.href ?? '/#'"
            :hreflang="locale"
            class="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-2xl sm:text-xl font-medium bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-green-500 focus:z-10 focus:ring-4 focus:ring-gray-200"
          >
            <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 16 4-4-4-4m6 8 4-4-4-4" />
            </svg>
            {{ section1?.button2?.text }}
          </NuxtLinkLocale>
        </div>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-6 lg:flex">
        <img fetchpriority="high" :src="section1?.image1" alt="" class="rounded-xl">
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
