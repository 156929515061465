<script setup lang="ts">
const { t, locale, defaultLocale }: any = useI18n()

const selectedIcon = computed(() => `/${locale.value}.svg`)

const sitedata: any = useSiteData()

const showLang = computed(() => !_has(sitedata.value, 'show_lang') || sitedata.value?.show_lang)

const sitedataLocales = computed(() => sitedata.value?.locales?.length ? sitedata.value?.locales : ['en', 'vi'])

function listLocales() {
  const items: any = [{
    label: t('language'),
    disabled: true,
  }]

  if (sitedataLocales.value?.length) {
    sitedataLocales.value.forEach((element: any) => {
      items.push({
        label: t(element),
        avatar: {
          src: `/${element}.svg`,
        },
        click: async () => {
          await navigateTo(defaultLocale === element ? '/' : `/${element}`, { external: true })
        },
      })
    })
  }

  return [items]
}

const localeItems = ref(listLocales())

// const localeItems = [
//   [{
//     label: 'English',
//     avatar: {
//       src: `/en.svg`,
//     },
//     click: async () => {
//       await navigateTo(defaultLocale === 'en' ? '/' : '/en', { external: true })
//     },
//   }, {
//     label: 'Tiếng Việt',
//     avatar: {
//       src: '/vi.svg',
//     },
//     click: async () => {
//       await navigateTo(defaultLocale === 'vi' ? '/' : '/vi', { external: true })
//     },
//   }],
// ]
</script>

<template>
  <UDropdown
    v-if="sitedataLocales?.length && showLang"
    :title="t('language')"
    :items="localeItems"
    :ui="{ item: { disabled: 'cursor-text select-text', padding: 'px-1.5 py-2', size: 'text-base' } }"
    :popper="{ placement: 'bottom-start' }"
    class="py-3 sm:my-5 sm:my-0"
  >
    <UAvatar :src="selectedIcon" alt="" size="xs" />
  </UDropdown>
</template>
