export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp()

  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered)
    return

  const { $i18n } = nuxtApp

  const currency = useCookie('currency')

  const sitedata: any = useSiteData()

  if (_isEmpty(sitedata.value)) {
    const host = await useGetHost()

    sitedata.value = await $fetch('/api/sitedata', {
      headers: { domain: host, locale: $i18n.locale.value },
    })
  }

  if (sitedata.value?.currency && !currency.value)
    currency.value = sitedata.value?.currency
})
