<script setup lang="ts">
const { locale }: any = useI18n()

const viewport = useViewport()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section27)
</script>

<template>
  <!-- Start block 27 -->
  <section v-if="data?.status" class="bg-white text-gray-800">
    <div class="mx-auto">
      <UCarousel
        :items="data?.list"
        :ui="{
          item: 'w-full h-screen',
          indicators: {
            active: 'bg-gray-500 dark:bg-gray-400',
          },
        }"
        :arrows="viewport.isGreaterOrEquals('tablet')"
        :indicators="viewport.isLessThan('tablet')"
      >
        <template #default="{ item }">
          <div class="flex items-center justify-center w-full h-full bg-cover bg-center bg-black/10 bg-blend-overlay" :style="`background-image: url(${item?.image1})`">
            <div class="flex flex-col items-center justify-center max-w-4xl">
              <h1 v-if="item?.text1" class="mx-auto text-center text-4xl lg:text-5xl xl:text-6xl text-white font-bold">
                {{ item?.text1 }}
              </h1>
              <div v-if="item?.text2" class="mx-auto text-center">
                <div v-if="_isArray(item?.text2)">
                  <p
                    v-for="(text2Item, text2Index) in item?.text2"
                    :key="`bjzwZZ${text2Index}`"
                    class="text-xl text-white mt-3"
                  >
                    {{ text2Item }}
                  </p>
                </div>
                <p v-else class="text-xl text-white mt-3">
                  {{ item?.text2 }}
                </p>
              </div>

              <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-10">
                <NuxtLinkLocale
                  v-if="item?.button1"
                  :to="item?.button1?.href ?? '/#'"
                  :hreflang="locale"
                  class="inline-flex items-center justify-center w-full px-5 py-3 text-2xl sm:text-xl font-medium text-center text-white border border-gray-600 rounded-lg sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-100"
                >
                  {{ item?.button1?.text }}
                </NuxtLinkLocale>

                <NuxtLinkLocale
                  v-if="item?.button2"
                  :to="item?.button2?.href ?? '/#'"
                  :hreflang="locale"
                  class="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-2xl sm:text-xl font-medium bg-white border border-gray-300 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-gray-500 focus:ring-4 focus:ring-gray-100"
                >
                  {{ item?.button2?.text }}
                </NuxtLinkLocale>
              </div>
            </div>
          </div>
        </template>

        <template #prev="{ onClick, disabled }">
          <UButton
            title="Prev"
            aria-label="prev-button"
            color="gray"
            icon="i-heroicons-chevron-left-16-solid"
            :disabled="disabled"
            size="xl"
            class="bg-transparent shadow-none border-0 ring-0 text-white hover:bg-transparent disabled:bg-transparent absolute top-[80%] sm:top-[45%] start-0 xl:-start-15"
            :ui="{
              icon: { size: { xl: 'h-16 w-16' } },
            }"
            @click="onClick"
          />
        </template>

        <template #next="{ onClick, disabled }">
          <UButton
            title="Next"
            aria-label="next-button"
            color="gray"
            icon="i-heroicons-chevron-right-16-solid"
            :disabled="disabled"
            size="xl"
            class="bg-transparent shadow-none border-0 ring-0 text-white hover:bg-transparent disabled:bg-transparent absolute top-[80%] sm:top-[45%] end-0 xl:-end-15"
            :ui="{
              icon: { size: { xl: 'h-16 w-16' } },
            }"
            @click="onClick"
          />
        </template>
      </UCarousel>
    </div>
  </section>
  <!-- End block 27 -->
</template>
