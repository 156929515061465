<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const sitedata: any = useSiteData()

const keyword = ref<any>(route?.query?.keyword ?? '')

async function onSelect() {
  if (!keyword.value)
    return false

  const to = checkHttp(_get(sitedata.value?.home_text, 9)) ? _get(sitedata.value?.home_text, 9) : localePath(_get(sitedata.value?.home_text, 9))

  await navigateTo({ path: to, query: { keyword: keyword.value } }, { external: true })
}
</script>

<template>
  <div class="mx-auto max-w-screen-lg px-2 pt-10 mb-10 sm:mb-20">
    <div class="container mx-auto">
      <div class="flex items-center justify-center">
        <div class="flex w-full mx-10 rounded-lg border-2 p-1">
          <UInput
            v-model="keyword"
            type="search"
            name="keywordproduct"
            color="gray"
            variant="none"
            :placeholder="t('search')"
            class="w-full h-10 border-none bg-transparent text-gray-600 outline-none focus:outline-none text-lg"
            size="xl"
            :ui="{
              label: {
                base: 'text-lg',
              },
            }"
            @keyup.enter="onSelect"
          />

          <UButton
            aria-label="search-product"
            icon="i-heroicons-magnifying-glass-20-solid"
            size="xl"
            color="black"
            square
            variant="solid"
            @click="onSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>
