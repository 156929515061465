<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section12)
</script>

<template>
  <!-- Start block 12 -->
  <section v-if="data?.status" class="bg-gray-50">
    <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
      <!-- Row -->
      <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <div class="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
            {{ data?.text1 }}
          </h2>
          <p class="mb-8 text-gray-800 font-light text-xl">
            {{ data?.text2 }}
          </p>
          <!-- List -->
          <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
            <li
              v-for="(item, index) in data?.list1"
              :key="`JVYDpy${index}`"
              class="flex space-x-3"
            >
              <!-- Icon -->
              <svg class="flex-shrink-0 w-7 h-7 text-sky-500 dark:text-sky-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /></svg>
              <span class="text-xl leading-tight text-gray-900 dark:text-white">{{ item }}</span>
            </li>
          </ul>
          <p class="mb-8 text-gray-800 font-light text-xl">
            {{ data?.text3 }}
          </p>
        </div>
        <img
          :src="data?.image1 ?? '/feature-1.png'"
          alt=""
          class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
        >
      </div>
    </div>
  </section>
  <!-- End block 12 -->
</template>
