<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section26)
</script>

<template>
  <!-- Start block 26 -->
  <section v-if="data?.status" class="bg-white text-gray-800">
    <div class="container mx-auto max-w-screen-xl px-5 py-24">
      <div class="text-center mb-20">
        <h2 v-if="data?.text1" class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ data?.text1 }}
        </h2>
        <p v-if="data?.text2" class="text-2xl sm:text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
          {{ data?.text2 }}
        </p>
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-500 inline-flex" />
        </div>
      </div>
      <div v-if="data?.list1?.length" class="lg:flex lg:flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
        <div
          v-for="(item, index) in data?.list1"
          :key="`cQzFVD${index}`"
          class="p-4 lg:w-1/3 flex flex-col text-center items-center"
        >
          <div v-if="item?.image1" class="w-full mx-auto inline-flex items-center justify-center rounded-full mb-5 flex-shrink-0">
            <img :src="item?.image1" loading="lazy" alt="" class="w-20 h-20 object-cover">
          </div>
          <div v-else class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
            <svg class="w-10 h-10" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="flex-grow">
            <div v-if="item?.text1" class="text-gray-900 text-3xl sm:text-2xl title-font font-bold mb-3">
              {{ item?.text1 }}
            </div>
            <p v-if="item?.text2" class="leading-relaxed text-2xl sm:text-xl">
              {{ item?.text2 }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 26 -->
</template>
