<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section35)
</script>

<template>
  <!-- Start block 35 -->
  <section v-if="data?.status && data?.list1?.length" :id="data?.anchor" class="text-gray-800">
    <div class="container mx-auto max-w-screen-xl px-5 py-24">
      <div class="text-center mb-10">
        <div v-if="data?.text1" class="mb-6 text-3xl sm:text-4xl font-bold">
          {{ data?.text1 }}
        </div>
        <p v-if="data?.text2" class="text-2xl sm:text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
          {{ data?.text2 }}
        </p>
      </div>
      <ol class="flex-col xl:flex-row flex items-center justify-center w-full p-3 space-x-2 text-lg font-medium text-center text-gray-500 bg-white rounded-lg sm:p-4 sm:space-x-4 rtl:space-x-reverse">
        <li
          v-for="(item, index) in data?.list1"
          :key="`ZAJSFy${index}`"
          class="flex items-center text-green-400 mb-3"
        >
          <span class="flex items-center justify-center w-64 h-64 sm:w-48 sm:h-48 p-2 text-2xl sm:text-xl font-bold text-gray-700 bg-white border border-8 border-green-500 rounded-full shrink-0">
            {{ `${index + 1}. ${item}` }}
          </span>
          <svg v-if="index < data?.list1?.length - 1" class="hidden xl:inline w-6 h-6 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
          </svg>
        </li>
      </ol>
    </div>
  </section>
  <!-- End block 35 -->
</template>
