<script setup lang="ts">
const { locale }: any = useI18n()

const sitedata: any = useSiteData()

const section1 = computed(() => sitedata.value?.home_data?.section1)
</script>

<template>
  <!-- Start block -->
  <section v-if="section1?.status" class="bg-gray-50 text-gray-800">
    <div class="grid max-w-screen-xl px-4 pt-16 pb-8 mx-auto lg:gap-8 xl:gap-3 lg:py-32 lg:grid-cols-12">
      <div class="mr-auto place-self-center lg:col-span-6">
        <h1 class="max-w-2xl mb-8 text-5xl sm:text-4xl md:text-5xl xl:text-6xl font-extrabold leading-none tracking-tight text-green-600">
          {{ section1?.text1 }}
        </h1>
        <div v-if="section1?.list1?.length" class="mb-6">
          <p
            v-for="(item, index) in section1?.list1"
            :key="`wZeQGB${index}`"
            class="flex items-center jusify-start mb-3 text-2xl sm:text-xl"
          >
            {{ item }}
          </p>
        </div>

        <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
          <NuxtLinkLocale
            :to="section1?.button1?.href ?? '/#'"
            :hreflang="locale"
            class="inline-flex items-center justify-center w-full px-5 py-3 text-2xl sm:text-xl font-medium text-center text-white border border-gray-200 rounded-lg sm:w-auto bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-gray-100"
          >
            {{ section1?.button1?.text }}
          </NuxtLinkLocale>

          <NuxtLinkLocale
            :to="section1?.button2?.href ?? '/#'"
            :hreflang="locale"
            class="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-2xl sm:text-xl font-medium bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-green-500 focus:z-10 focus:ring-4 focus:ring-gray-200"
          >
            {{ section1?.button2?.text }}
          </NuxtLinkLocale>
        </div>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-6 lg:flex">
        <img fetchpriority="high" :src="section1?.image1" alt="" class="rounded-xl">
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
