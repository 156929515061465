<script setup lang="ts">
const { locale } = useI18n()

const sitedata: any = useSiteData()

const section = computed(() => sitedata.value?.homepage_section?.section25)

const host = await useGetHost('host')

const { data }: any = await useFetch('/api/homepage', {
  headers: { domain: host, locale: locale.value },
})
</script>

<template>
  <!-- Start block 25 -->
  <section v-if="section?.status" class="bg-white">
    <div class="mx-auto max-w-screen-xl px-2 py-16">
      <div class="container mx-auto">
        <div class="pt-5 lg:pt-15">
          <ProductIndex :data="data">
            <template #more>
              <div class="w-full text-center my-10">
                <UButton
                  color="gray"
                  :label="section?.button1?.text"
                  :to="section?.button1?.href"
                  size="xl"
                  variant="solid"
                  :ui="{
                    base: 'xl:px-5',
                    rounded: 'rounded-full',
                  }"
                >
                  <template #trailing>
                    <UIcon name="i-heroicons-chevron-double-right-20-solid" />
                  </template>
                </UButton>
              </div>
            </template>
          </ProductIndex>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 25 -->
</template>
