<script lang="ts" setup>
import { ModalInvest } from '#components'

const props = defineProps<{
  data?: any
}>()

const { t } = useI18n()

const sitedata: any = useSiteData()

const modal = useModal()

function openModal(type: any) {
  modal.open(ModalInvest, {
    data: props?.data,
    type,
    onSuccess() {
      // toastAdd({ title: 'Success !', id: 'modal-success' })
      modal.close()
    },
  })
}
</script>

<template>
  <div v-if="props?.data?.qty !== 0 && sitedata?.invest" class="mx-auto mb-2 w-11/12">
    <div class="grid grid-cols-2 gap-3">
      <UButton
        size="sm"
        color="gray"
        variant="solid"
        :label="t('investment')"
        :trailing="false"
        padded
        truncate
        :ui="{
          base: 'mx-auto flex h-10 w-full xl:p-3 items-center justify-center',
          font: 'uppercase',
        }"
        @click="openModal(1)"
      />
      <UButton
        size="sm"
        color="gray"
        variant="solid"
        :label="t('distribution')"
        :trailing="false"
        padded
        truncate
        :ui="{
          base: 'mx-auto flex h-10 w-full xl:p-3 items-center justify-center',
          font: 'uppercase',
        }"
        @click="openModal(2)"
      />
    </div>
  </div>
</template>
