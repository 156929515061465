<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section21)
</script>

<template>
  <!-- Start block 21 -->
  <section v-if="data?.status && data?.list?.length" :id="data?.anchor" class="bg-gray-100 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-2 py-16">
      <div class="container mx-auto">
        <div class="px-4 py-8">
          <div class="max-w-full max-lg:max-w-5xl mx-auto">
            <div class="text-center">
              <h2 class="mb-6 text-4xl sm:text-5xl font-bold">
                {{ data?.title }}
              </h2>
              <p class="text-2xl sm:text-xl text-gray-600">
                {{ data?.caption }}
              </p>
            </div>

            <div v-if="data?.list?.length" class="grid lg:grid-cols-3 sm:grid-cols-2 gap-6 max-sm:max-w-xl max-sm:mx-auto mt-12">
              <div
                v-for="(item, index) in data?.list"
                :key="`YTXIjA${index}`"
                class="bg-white shadow rounded-3xl p-6 hover:scale-105 transition-all duration-300"
              >
                <div class="text-2xl sm:text-xl mb-5">
                  {{ item?.title }}
                </div>
                <div class="text-4xl font-semibold ">
                  {{ item?.price }}<sub class="text-gray-600 font-medium text-lg ml-1">{{ item?.sub }}</sub>
                </div>

                <button
                  type="button"
                  class="w-full mt-8 px-4 py-2 text-2xl sm:text-xl tracking-wide bg-blue-600 hover:bg-blue-700 text-white rounded-xl"
                  @click="navigateTo(localePath(item?.button?.href ?? '/#'), { external: true })"
                >
                  {{ item?.button?.text }}
                </button>

                <hr class="my-6 border-gray-300">

                <div>
                  <ul class="space-y-4">
                    <li
                      v-for="(ite, ind) in item?.items"
                      :key="`sKyhtd${ind}`"
                      class="flex items-center text-2xl sm:text-xl text-gray-700"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" class="h-6 w-6 mr-3 shrink-0 bg-blue-100 fill-blue-500 rounded-full p-[3px]" viewBox="0 0 24 24">
                        <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                      </svg>
                      <span>{{ ite }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 21 -->
</template>
