<script setup lang="ts">
definePageMeta({
  layout: false,
})

const { locale } = useI18n()
const localePath = useLocalePath()

const hostPort = await useGetHost('hostport')
const host = _get(hostPort, 0)
const port = _get(hostPort, 1)

const { data }: any = await useFetch('/api/homepage', {
  headers: { domain: host, locale: locale.value },
})

const sitedata: any = useSiteData()

const uri = port ? `http://${host}:${port}${localePath('/')}` : `http://${host}${localePath('/')}`

useHead({
  title: sitedata.value?.meta_title,
  meta: [
    { name: 'description', content: sitedata.value?.meta_description || sitedata.value?.meta_title },
    { property: 'og:title', content: sitedata.value?.meta_title },
    { property: 'og:description', content: sitedata.value?.meta_description || sitedata.value?.meta_title },
    { property: 'og:image', content: sitedata.value?.meta_image || sitedata.value?.logo },
    // { property: 'og:image:type', content: 'image/png' },
    // { property: 'og:image:width', content: '300' },
    // { property: 'og:image:height', content: '300' },
    // { property: 'og:image:alt', content: host },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: sitedata.value?.meta_title },
    { property: 'og:url', content: uri },
    // { name: 'twitter:card', content: 'summary_large_image' },
    // { name: 'twitter:site', content: `@${host}` },
    // { name: 'twitter:title', content: sitedata.value?.meta_title },
    // { name: 'twitter:description', content: sitedata.value?.meta_description },
    // { name: 'twitter:image', content: sitedata.value?.meta_image || sitedata.value?.logo },
  ],
  link: [
    {
      hid: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: sitedata.value?.favicon ? sitedata.value?.favicon : null,
    },
  ],
})
</script>

<template>
  <div v-if="sitedata?.layout === 'singlepage'">
    <NuxtLayout name="singlepage">
      <SinglePage :data="data" />
    </NuxtLayout>
  </div>

  <div v-else-if="sitedata?.layout === 'layout1'">
    <NuxtLayout name="layout1">
      <Layout1Index />
    </NuxtLayout>
  </div>

  <div v-else-if="sitedata?.layout === 'layout2'">
    <NuxtLayout name="layout2">
      <Layout2Index />
    </NuxtLayout>
  </div>

  <div v-else-if="sitedata?.layout === 'layout4'">
    <NuxtLayout name="layout4">
      <Layout4Index />
    </NuxtLayout>
  </div>

  <div v-else-if="sitedata?.layout === 'layout5'">
    <NuxtLayout name="layout5">
      <Layout5Index />
    </NuxtLayout>
  </div>

  <div v-else-if="sitedata?.layout === 'layout0'">
    <NuxtLayout name="layout0">
      <Layout0Index />
    </NuxtLayout>
  </div>

  <div v-else>
    <NuxtLayout name="default">
      <DefaultHero />

      <SearchProduct />

      <div class="pt-5 lg:pt-15">
        <ProductIndex :data="data">
          <template #more>
            <div class="w-full text-center my-10">
              <UButton
                color="gray"
                :label="_get(sitedata?.home_text, 8)"
                :to="checkHttp(_get(sitedata?.home_text, 9)) ? _get(sitedata?.home_text, 9) : localePath(_get(sitedata?.home_text, 9))"
                size="xl"
                variant="solid"
                :ui="{
                  base: 'xl:px-5',
                  rounded: 'rounded-full',
                }"
              >
                <template #trailing>
                  <UIcon name="i-heroicons-chevron-double-right-20-solid" />
                </template>
              </UButton>
            </div>
          </template>
        </ProductIndex>
      </div>

      <PricingBlock />
    </NuxtLayout>
  </div>
</template>
