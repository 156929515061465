<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section5)
</script>

<template>
  <!-- Start block 5 -->
  <section v-if="data?.status && data?.list?.length" class="py-24 text-gray-800">
    <div
      v-for="(item, index) in data?.list"
      :key="`ggWUYG${index}`"
      class="mx-auto my-10 grid max-w-screen-xl grid-cols-1 px-2 sm:px-20 lg:grid-cols-3"
    >
      <div class="col-span-1 flex flex-col justify-center text-center sm:text-left md:pr-10">
        <div class="mb-6 text-4xl font-bold">
          {{ item?.text1 }}
        </div>
      <!-- <p class="text-blue-900">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero.
      </p> -->
      </div>
      <div class="col-span-2 mt-10 grid grid-cols-1 gap-6 rounded-2xl bg-gray-50 p-5 sm:p-10 md:grid-cols-2 lg:mt-0">
        <div
          v-for="(ite, ind) in item?.list1"
          :key="`FdpnNr${ind}`"
          class="relative flex gap-5"
        >
          <div class="absolute -left-8 -top-8 sm:-left-12 flex h-10 w-10 items-center justify-center rounded-full bg-white text-2xl md:text-5xl text-blue-300 lg:text-blue-200 sm:static sm:bg-transparent">
            {{ ind + 1 }}
          </div>
          <div class="">
            <!-- <h3 class="text-xl font-semibold">
            Beginning
          </h3> -->
            <p class="text-2xl sm:text-xl text-gray-700">
              {{ ite }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 5 -->
</template>
