export const datetimeFormats = {
  en: {
    // 👇 Give a key for each format...
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    // We can have as many formats here as
    // want e.g. long, footer_copyright, etc.
  },
  vi: {
    // 👇 ...use the same keys for each locale
    short: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  cn: {
    // 👇 Give a key for each format...
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    // We can have as many formats here as
    // want e.g. long, footer_copyright, etc.
  },
} as const // 👈 Cast to avoid TypeScript errors
