export default function (locale: any, currency: any, nodefault: boolean = false) {
  return !currency
    ? (nodefault ? null : (locale ?? null))
    : (
        currency === 'VND'
          ? 'vi'
          : (
              currency === 'USD'
                ? 'en'
                : (
                    currency === 'EUR'
                      ? 'eu'
                      : (
                          currency === 'CNY'
                            ? 'cn'
                            : null
                        )
                  )
            )
      )
}
