<script setup lang="ts">
const sitedata: any = useSiteData()

const homeData = computed(() => sitedata.value?.home_data)
</script>

<template>
  <div
    v-for="[key] of Object.entries(homeData)"
    :key="`xXfjuC${key}`"
  >
    <Layout4Carousel v-if="key === 'section1'" />

    <Layout4Feature v-if="key === 'section3'" />

    <Layout4Feature2 v-if="key === 'section4'" />

    <Layout4Feature3 v-if="key === 'section5'" />

    <Layout4Feature4 v-if="key === 'section6'" />

    <Layout4Feature5 v-if="key === 'section7'" />

    <Layout4Logo v-if="key === 'section8'" />

    <Layout4Contact v-if="key === 'section10'" />
  </div>
</template>
