<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section15)
</script>

<template>
  <!-- Start block 15 -->
  <section v-if="data?.status" class="bg-gray-50">
    <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
      <figure class="max-w-screen-md mx-auto">
        <svg class="h-12 mx-auto mb-3 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
        </svg>
        <blockquote>
          <p class="text-xl text-gray-900 md:text-2xl">
            {{ data?.text1 }}
          </p>
        </blockquote>
        <figcaption class="flex items-center justify-center mt-6 space-x-3">
          <!-- <img class="w-6 h-6 rounded-full" src="avatar.png" alt="profile picture"> -->
          <div class="flex items-center divide-x-2 divide-gray-500">
            <!-- <div class="pr-3 font-medium text-gray-900">
                  Avatar Name
                </div> -->
            <div class="pl-3 text-base font-light text-gray-800">
              {{ data?.text2 }}
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
  </section>
  <!-- End block 15 -->
</template>
