<script setup lang="ts">
const { t } = useI18n()

const sitedata: any = useSiteData()

const policy: any = useCookie('policy')
</script>

<template>
  <section
    v-if="!policy && sitedata?.cookie_section"
    class="print:hidden fixed flex items-center justify-between max-w-4xl w-[75%] md:w-[80%] lg:w-auto p-4 mx-auto bg-white border border-gray-200 left-2 bottom-2 md:gap-x-4 rounded-2xl z-[999]"
  >
    <p class="text-sm text-gray-600 cursor-pointer" @click="policy = 1">
      {{ t('cookie_note') }}
    </p>

    <button
      title="Cookie"
      aria-label="cookie"
      class="flex items-center justify-center text-gray-700 transition-colors duration-300 rounded-full shrink-0 w-7 h-7 focus:outline-none hover:bg-gray-100"
      @click="policy = 1"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
    </button>
  </section>
</template>
