<template>
  <Layout2Hero />

  <Layout2Feature />

  <Layout2Feature2 />

  <Layout2Feature3 />

  <Layout2Feature4 />

  <Layout2Feature5 />

  <PricingBlock />

  <Layout2Logo />

  <Layout2Contact />
</template>
