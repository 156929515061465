<template>
  <Layout1Hero />

  <Layout1Logo />

  <Layout1Feature />

  <Layout1Stat />

  <Layout1Testimonial />

  <Layout1Project />

  <Layout1Accordion />

  <Layout1Blog />

  <Layout1Contact />
</template>
