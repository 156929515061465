<script setup lang="ts">
import { destr } from 'destr'

const props = defineProps<{
  data?: any
}>()

const viewport = useViewport()

const { t, n, locale } = useI18n()
const localePath = useLocalePath()

const host = await useGetHost('host')

const headers: any = { domain: host, locale: locale.value }

const url = useRequestURL()

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const currencyKey = getCurrencyKey(locale.value, currency.value)

const cartStorageKey = getCartStorageKey({ site_type: sitedata.value?.site_type, locale: locale.value, currency: currency.value })

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey)) : {}

const cart: any = ref(cartStorage ?? {})

const addCartLoading = ref(false)

const times = computed(() => sitedata.value?.payment_period ?? 1)

async function saveCart() {
  if (cart.value?.items?.length === 0 && !cart.value?.id)
    return false

  const data: any = await $fetch('/api/cart', {
    method: 'post',
    headers,
    body: cart.value,
  })

  if (data)
    _set(cart.value, 'id', data)

  return false
}

async function saveCartShop() {
  cart.value?.items_grouped?.forEach(async (group: any, index: any) => {
    let shopCart = _omit(cart.value, ['items_grouped'])

    shopCart = Object.assign(shopCart, group)

    const data: any = await $fetch('/api/cart', {
      method: 'post',
      headers,
      body: shopCart,
    })

    if (!group?.id && data) {
      _set(cart.value, `items_grouped.${index}.id`, data)

      if (import.meta.client)
        localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))
    }
  })
}

async function addCart(item: any) {
  addCartLoading.value = true

  // check item in cart
  const index = _findIndex(cart.value?.items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (sitedata.value?.currencies?.length)
    _set(cartItem, 'unit_price', getUnitPrice(item, currencyKey))

  if (props?.data?.order_type)
    _set(cartItem, 'order_type', props?.data?.order_type)

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const items = _uniqBy(_concat(cart.value?.items ?? [], cartItem), 'id')

  cart.value.items = items

  if (sitedata.value?.currencies?.length && currency.value)
    _set(cart.value, 'currency', currency.value)

  await saveCart()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function addCartShop(item: any) {
  addCartLoading.value = true

  const indexGroup = _findIndex(cart.value?.items_grouped, { shop_id: item?.shop_id })

  const items = _get(cart.value?.items_grouped, `${indexGroup}.items`)

  const index = _findIndex(items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (sitedata.value?.currencies?.length)
    _set(cartItem, 'unit_price', getUnitPrice(item, currencyKey))

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const itemsGroup = _uniqBy(_concat(items ?? [], cartItem), 'id')

  if (indexGroup > -1) {
    _set(cart.value, `items_grouped.${indexGroup}.items`, itemsGroup)
  }
  else {
    if (cart.value?.items_grouped?.length) {
      cart.value.items_grouped = [
        ...cart.value.items_grouped,
        ...[
          {
            items: itemsGroup,
            shop_id: item?.shop_id,
            shop_name: item?.shop_name,
            shop_slug: item?.shop_slug,
          },
        ],
      ]
    }
    else {
      _set(cart.value, 'items_grouped.0', {
        items: itemsGroup,
        shop_id: item?.shop_id,
        shop_name: item?.shop_name,
        shop_slug: item?.shop_slug,
      })
    }
  }

  if (sitedata.value?.currencies?.length && currency.value)
    _set(cart.value, 'currency', currency.value)

  await saveCartShop()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function onAddToCart(item: any) {
  if (sitedata.value?.site_type === 3)
    await navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))
  else if (sitedata.value?.site_type === 4)
    addCartShop(item)
  else
    addCart(item)
}
</script>

<template>
  <ProductCategoryMobile :data="props?.data?.product_category" />

  <div class="mx-auto relative flex gap-5 flex-col md:flex-row px-2 xl:px-0" :class="sitedata?.product_per_row > 3 ? 'max-w-screen-2xl' : 'max-w-screen-xl'">
    <ProductSide :data="props?.data?.product_category" />

    <div v-if="props?.data?.items?.length" class="w-full grow-2 lg:px-2">
      <div
        class="grid grid-cols-2 md:grid-cols-3 xl:px-0 gap-3"
        :class="sitedata?.product_per_row === 3 ? 'xl:grid-cols-3' : (sitedata?.product_per_row === 4 ? 'xl:grid-cols-4' : (sitedata?.product_per_row === 5 ? 'xl:grid-cols-5' : 'xl:grid-cols-3'))"
      >
        <article
          v-for="(item, index) in props?.data?.items"
          :key="`uYYBka${index}`"
          class="relative flex flex-col overflow-hidden rounded-lg border mb-3 justify-between"
        >
          <div class="aspect-square overflow-hidden cursor-pointer" @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))">
            <NuxtImg
              v-if="item?.image && checkImage(_get(item, 'image.0.thumbnail') ?? _get(item, 'image.0.link'))"
              loading="lazy"
              :src="_get(item, 'image.0.thumbnail') ?? _get(item, 'image.0.link')"
              alt=""
              class="h-full w-full object-cover transition-all duration-300 hover:scale-110"
            />
          </div>
          <div class="my-4 mx-auto flex w-11/12 flex-col items-start justify-between">
            <div v-if="item?.unit_price" class="mb-2 flex">
              <p class="mr-3 text-lg font-semibold">
                <ClientOnly fallback-tag="span">
                  {{ n(getUnitPrice(item, currencyKey), 'currency', currencyKey) }}
                </ClientOnly>
              </p>
            </div>
            <h3 class="mb-2 text-lg text-gray-600 hover:text-gray-800 cursor-pointer" @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))">
              {{ item?.name }}
            </h3>
          </div>

          <div v-if="sitedata?.hascart" class="mx-auto mb-2 w-11/12">
            <div v-if="item?.qty === 0" class="text-center text-orange-600 h-10">
              {{ sitedata?.site_type === 3 ? t('out_of_stock_room') : t('out_of_stock') }}
            </div>
            <UButton
              v-else
              :disabled="addCartLoading"
              icon="i-heroicons-plus"
              size="sm"
              color="gray"
              variant="solid"
              :label="sitedata?.site_type === 3 ? t('book_now') : t('add_to_cart')"
              :trailing="false"
              padded
              truncate
              :ui="{
                base: 'mx-auto flex h-10 w-full xl:p-3 items-center justify-center',
                font: 'uppercase',
              }"
              @click="onAddToCart(item)"
            />
          </div>

          <ButtonInvest :data="item" />
        </article>
      </div>

      <slot name="more" />
    </div>

    <div v-else class="mx-auto text-center text-lg">
      {{ t('search_no_items') }}
    </div>

    <div v-if="viewport.isLessOrEquals('tablet')">
      <SupportSocial />

      <QrCode
        v-if="url.href"
        :data="url.href"
        img-class="md:h-52 md:w-52"
        caption-class="w-full mx-auto"
      />
    </div>
  </div>
</template>
