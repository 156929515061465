<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section31)
</script>

<template>
  <!-- Start block 31 -->
  <section v-if="data?.status && data?.list?.length" class="bg-white text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-16">
      <div class="mb-10 md:mb-16">
        <div v-if="data?.text1" class="text-center mb-6 text-4xl sm:text-5xl font-bold">
          {{ data?.text1 }}
        </div>

        <p v-if="data?.text2" class="mx-auto max-w-screen-md text-center text-2xl sm:text-xl text-gray-600">
          {{ data?.text2 }}
        </p>
      </div>

      <div class="grid gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-8">
        <!-- article - start -->
        <div
          v-for="(item, index) in data?.list"
          :key="`OwAiCw${index}`"
          class="group relative flex h-48 flex-col overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-64 xl:h-96 cursor-pointer"
          @click="navigateTo(localePath(item?.href ?? '/#'))"
        >
          <img v-if="item?.image" :src="item?.image" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">

          <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent" />

          <div class="relative mt-auto p-4">
            <span v-if="item?.text2" class="block text-sm text-gray-200">{{ item?.text2 }}</span>

            <div v-if="item?.text1" class="mb-2 text-xl font-semibold text-white transition duration-100">
              {{ item?.text1 }}
            </div>

            <span class="font-semibold text-sky-500">{{ t('read_more') }}</span>
          </div>
        </div>
        <!-- article - end -->
      </div>
    </div>
  </section>
  <!-- End block 31 -->
</template>
