<script setup lang="ts">
const { locale } = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section34)
</script>

<template>
  <!-- Start block 34 -->
  <section v-if="data?.status" class="bg-white text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-16">
      <div class="flex flex-col items-center justify-between gap-4 rounded-lg bg-gray-100 px-4 py-8 sm:flex-row md:p-8">
        <div class="mb-3 md:mb-0">
          <h2 v-if="data?.text1" class="mb-6 text-4xl sm:text-5xl font-bold">
            {{ data?.text1 }}
          </h2>
          <p v-if="data?.text2" class="text-2xl sm:text-xl text-gray-600">
            {{ data?.text2 }}
          </p>
        </div>

        <NuxtLinkLocale
          :to="data?.link1?.href ?? '/#'"
          :hreflang="locale"
          class="inline-block rounded-lg bg-indigo-500 px-8 py-5 text-center text-2xl font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700"
        >
          {{ data?.link1?.text }}
        </NuxtLinkLocale>
      </div>
    </div>
  </section>
  <!-- End block 34 -->
</template>
