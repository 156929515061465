<script setup lang="ts">
import { destr } from 'destr'

const emit = defineEmits(['change'])

const { t, locale } = useI18n()

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const cartStorageKey = getCartStorageKey({ site_type: sitedata.value?.site_type, locale: locale.value, currency: currency.value })

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey)) : {}

const cart: any = ref(cartStorage ?? {})

const selected = ref(cart.value?.payment_method)

const paymentMethodSite = computed(() => sitedata.value?.payment_methods ?? [1])

const paymentMethodsItems = ref<any>([])

const paymentMethodLabel = {
  1: t('bank_transfer'),
  5: t('payment_card'),
  6: 'Paypal',
  101: t('balance_cash'),
  100: t('cod'),
  102: t('direct'),
}

const paymentMethodContent = {
  1: t('bank_transfer_note'),
  5: t('payment_card_note'),
  6: t('paypal_note'),
  101: t('balance_cash_note'),
  100: t('cod_note'),
  102: t('direct_note'),
}

function setPaymentMethods() {
  if (paymentMethodSite.value?.length) {
    paymentMethodSite.value.forEach((item: any) => {
      paymentMethodsItems.value.push({
        value: item,
        label: _get(paymentMethodLabel, item),
        icon: 'i-heroicons-information-circle',
        defaultOpen: (cart.value?.payment_method === item),
        content: _get(paymentMethodContent, item),
      })
    })
  }
}

setPaymentMethods()

const authenticated = useAuthenticated()

if (!authenticated.value)
  paymentMethodsItems.value = paymentMethodsItems.value.filter((e: any) => e.value !== 101)

function onSelect(v: any) {
  selected.value = v

  emit('change', selected.value)
}
</script>

<template>
  <div class="mx-auto my-6">
    <div class="mt-12 mb-6 block">
      <h3 class="text-2xl font-bold text-gray-700">
        {{ t('payment_method') }}
      </h3>
      <p v-if="sitedata?.payment?.note" class="text-gray-500 my-3">
        {{ t('select_payment_method') }}
      </p>
    </div>
    <UAccordion
      :items="paymentMethodsItems"
      :ui="{
        wrapper: 'flex flex-col w-full',
      }"
    >
      <template #item="{ item }">
        <p class="text-lg pl-12">
          {{ _get(item, `content${locale === 'vi' ? '' : `_${locale}`}`) ?? _get(item, 'content') }}
        </p>
      </template>
      <template #default="{ item }">
        <UButton
          color="gray"
          variant="ghost"
          class="border-b border-gray-200"
          size="xl"
          :ui="{
            rounded: 'rounded-none',
            padding: { sm: 'p-3' },
          }"
          @click="onSelect(item?.value)"
        >
          <template #leading>
            <div class="w-6 h-6 rounded-full bg-primary-500 flex items-center justify-center -my-1">
              <URadio
                v-model="selected"
                :value="item?.value"
                :ui="{
                  base: 'h-6 w-6',
                }"
                @click="onSelect(selected)"
              />
            </div>
          </template>

          <span class="truncate text-xl">
            {{ _get(item, `label${locale === 'vi' ? '' : `_${locale}`}`) ?? _get(item, 'label') }}
          </span>
        </UButton>
      </template>
    </UAccordion>
  </div>
</template>
