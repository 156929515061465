<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section20)
</script>

<template>
  <!-- Start block 20 -->
  <section v-if="data?.status && data?.list?.length" class="py-24 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div
          v-for="(item, index) in data?.list"
          :key="`jYhKya${index}`"
          class="bg-gray-100 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden border"
          @click="navigateTo(localePath(item?.href ?? '/#'))"
        >
          <div class="bg-gray-200">
            <div class="w-full mx-auto">
              <img :src="item?.image1" loading="lazy" alt="" class="h-full w-full object-cover">
            </div>
          </div>

          <div class="text-center bg-gray-100 p-6">
            <p class="text-2xl sm:text-xl font-medium text-gray-600">
              {{ item?.text1 }}
            </p>

            <div class="w-full mt-8 px-5 py-3 text-2xl sm:text-xl tracking-wide bg-green-600 hover:bg-green-700 text-white rounded-xl">
              {{ item?.text2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 20 -->
</template>
