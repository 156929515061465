<script setup lang="ts">
const sitedata: any = useSiteData()

const section6 = computed(() => sitedata.value?.home_data?.section6)
</script>

<template>
  <!-- Start block -->
  <section v-if="section6?.status" class="bg-gray-50 text-gray-800">
    <div class="mx-auto grid max-w-screen-xl gap-x-8 gap-y-12 px-4 py-32 md:grid-cols-2 md:px-8 lg:grid-cols-3">
      <div class="flex items-center">
        <div class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ section6?.text1 }}
        </div>
      </div>

      <div
        v-for="(item, index) in section6?.list1"
        :key="`RJZxiC${index}`"
        class="rounded-xl border-blue-100 relative border-4 px-4 pt-14 pb-8"
      >
        <div class="absolute -top-6 left-6 bg-blue-100 px-4 w-12 h-12 rounded-full flex items-center">
          <span class="text-blue-500 text-3xl font-bold">
            {{ index + 1 }}
          </span>
        </div>
        <!-- <p class="mb-3 font-medium uppercase text-white">
          Integrated Terminal
        </p> -->
        <p class="text-2xl sm:text-xl text-gray-700">
          {{ item }}
        </p>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
