<script setup lang="ts">
import { ModalGallery } from '#components'

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section33)

const modal = useModal()

function openModal(item: any) {
  modal.open(ModalGallery, {
    data: item,
    onSuccess() {
      // toastAdd({ title: 'Success !', id: 'modal-success' })
      modal.close()
    },
  })
}
</script>

<template>
  <!-- Start block 34 -->
  <section v-if="data?.status && data?.list?.length" class="bg-gray-50 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-16">
      <div class="mb-10 md:mb-16">
        <h2 v-if="data?.text1" class="text-center mb-6 text-4xl sm:text-5xl font-bold">
          {{ data?.text1 }}
        </h2>

        <p v-if="data?.text2" class="mx-auto max-w-screen-md text-center text-2xl sm:text-xl text-gray-600">
          {{ data?.text2 }}
        </p>
      </div>

      <div class="mb-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:mb-8 md:grid-cols-4 md:gap-6 xl:gap-8">
        <!-- image - start -->
        <div
          v-for="(item, index) in data?.list"
          :key="`GmMBSN${index}`"
          class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
          @click="openModal(item)"
        >
          <img v-if="item?.image" :src="item?.image" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">

          <div v-if="item?.text1" class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50" />

          <span v-if="item?.text1" class="relative ml-4 mb-3 inline-block text-base text-white md:ml-5 md:text-lg">{{ item?.text1 }}</span>
        </div>
        <!-- image - end -->
      </div>

      <!-- <div class="flex items-start justify-between gap-8 sm:items-center">
        <p class="max-w-screen-sm text-sm text-gray-500 lg:text-base">
          This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.
        </p>

        <a href="#" class="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base">More</a>
      </div> -->
    </div>
  </section>
  <!-- End block 34 -->
</template>
