<script setup lang="ts">
const sitedata: any = useSiteData()

const localePath = useLocalePath()

const data = computed(() => sitedata.value?.homepage_section?.section3)
</script>

<template>
  <!-- Start block 3 -->
  <section v-if="data?.status && data?.list?.length" class="py-24 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-20">
        <h2 class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ data?.text1 }}
        </h2>
        <p v-if="data?.text2" class="text-2xl sm:text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
          {{ data?.text2 }}
        </p>
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-500 inline-flex" />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-36">
        <div
          v-for="(item, index) in data?.list"
          :key="`jYhKya${index}`"
          class="rounded-xl relative overflow-hidden"
          @click="navigateTo(localePath(item?.href ?? '/#'))"
        >
          <div class="bg-[#00AF50]">
            <div class="w-full mx-auto">
              <img :src="item?.image1" loading="lazy" alt="" class="h-50 w50 object-cover mx-auto">
            </div>
          </div>

          <div class="text-center p-6">
            <p class="text-2xl font-bold text-gray-600">
              {{ item?.text1 }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 3 -->
</template>
