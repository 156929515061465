<script setup lang="ts">
const { locale }: any = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section22)
</script>

<template>
  <!-- Start block 22 -->
  <section v-if="data?.status" class="px-2 lg:pt-20 mb-20">
    <div class="container max-w-screen-2xl mx-auto px-5 lg:px-8">
      <div class="flex text-center items-center relative max-w-full mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-52 absolute lg:block hidden right-[80%] top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 296 136"><path fill="#FF6100" d="M158.63 81.37a32 32 0 1 1-45.26 45.26 32 32 0 0 1 45.26-45.26Z" /><path fill="#FFB629" d="M210.14 29.86a20 20 0 1 1-28.28 28.28 20 20 0 0 1 28.28-28.28Z" /><path fill="#9356D0" d="M293.66 58.34a8 8 0 1 1-11.32 11.32 8 8 0 0 1 11.32-11.32Z" /><path fill="#78BB07" d="M252.49 83.51a12 12 0 1 1-16.97 16.97 12 12 0 0 1 16.97-16.97Z" /><path fill="#09F" d="M75.11 12.89A44 44 0 1 1 12.9 75.1a44 44 0 0 1 62.2-62.2Z" /></svg>
        <div class="w-full grow-1 lg:shrink-0 lg:px-6 z-10">
          <p v-if="data?.text1" class="mx-auto -mt-4 text-lg tracking-tight text-slate-700 sm:mt-6">
            <span class="border-b border-dotted border-slate-300">
              {{ data?.text1 }}
            </span>
          </p>

          <div class="mx-auto font-display text-4xl sm:text-6xl font-bold tracking-tight text-slate-900">
            <h1
              v-if="data?.text2"
              class="block whitespace-normal mb-2"
              :style="`color: ${sitedata?.primary_color ?? '#00701a'}`"
            >
              {{ data?.text2 }}
            </h1>
            <h2 v-if="data?.text3" class="block">
              {{ data?.text3 }}
            </h2>
          </div>

          <h3 v-if="data?.text4" class="line-height-2xl lg:line-height-3xl text-xl tracking-tight color-navy-700 mt-6 mb-8 mx-auto">
            {{ data?.text4 }}
          </h3>

          <div class="mt-6 flex flex-col justify-center gap-y-5 sm:flex-row sm:gap-y-0 sm:gap-x-6">
            <NuxtLinkLocale
              :to="data?.button1?.href ?? '/#'"
              :hreflang="locale"
              class="inline-flex items-center justify-center w-full px-10 py-2.5 text-xl text-center border border-gray-300 rounded-full sm:w-auto bg-gray-900 hover:bg-gray-800 text-white"
            >
              {{ data?.button1?.text }}
            </NuxtLinkLocale>

            <NuxtLinkLocale
              :to="data?.button2?.href ?? '/#'"
              :hreflang="locale"
              class="inline-flex items-center justify-center w-full px-10 py-2.5 text-xl text-center border border-gray-300 rounded-full sm:w-auto bg-white hover:bg-gray-100 text-gray-60"
            >
              {{ data?.button2?.text }}
            </NuxtLinkLocale>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-52 absolute lg:block hidden left-[80%] top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 296 136"><path d="M137.37 54.63a32 32 0 1 1 45.26-45.26 32 32 0 0 1-45.26 45.26Z" fill="#FF6100" /><path d="M85.86 106.14a20 20 0 1 1 28.28-28.28 20 20 0 0 1-28.28 28.28Z" fill="#FFB629" /><path d="M2.34 77.66a8 8 0 1 1 11.32-11.32A8 8 0 0 1 2.34 77.66Z" fill="#9356D0" /><path d="M43.51 52.49A12 12 0 1 1 60.48 35.5 12 12 0 0 1 43.51 52.5Z" fill="#78BB07" /><path d="M220.89 123.11A44 44 0 1 1 283.1 60.9a44 44 0 0 1-62.22 62.22Z" fill="#09F" /></svg>
      </div>
    </div>
  </section>
  <!-- End block 22 -->
</template>
