<script setup lang="ts">
const { t, locale } = useI18n()

const sitedata: any = useSiteData()

const section10 = computed(() => sitedata.value?.home_data?.section10)
</script>

<template>
  <!-- Start block -->
  <section v-if="section10?.status" class="bg-gray-50 text-gray-800">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
      <div class="max-w-screen-sm mx-auto text-center">
        <div class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ section10?.text1 ?? t('contact_us') }}
        </div>
        <NuxtLinkLocale
          :to="section10?.button1?.href ?? '/#'"
          :hreflang="locale"
          class="inline-flex items-center justify-center w-full px-5 py-3 text-2xl sm:text-xl font-medium text-center text-white border border-gray-200 rounded-lg sm:w-auto bg-sky-500 hover:bg-sky-400 focus:ring-4 focus:ring-gray-100"
        >
          {{ section10?.button1?.text ?? t('contact') }}
        </NuxtLinkLocale>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
