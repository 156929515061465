<script setup lang="ts">
const sitedata: any = useSiteData()

const localePath = useLocalePath()

const section3 = computed(() => sitedata.value?.home_data?.section3)
</script>

<template>
  <!-- Start block -->
  <section v-if="section3?.status && section3?.list?.length" class="py-24 text-gray-800">
    <!-- <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div
        class="flex justify-center items-center gap-x-5 gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8"
      >
        <div
          v-for="(item, index) in section3"
          :key="`szzNEG${index}`"
          class="relative w-full text-center max-md:max-w-sm max-md:mx-auto md:w-2/6 lg:w-1/3 mb-10 p-4"
          @click="navigateTo(item?.href ?? '/#')"
        >
          <div class="rounded-xl flex justify-center items-center mb-5 w-50 h-50 mx-auto transition-all duration-500">
            <img fetchpriority="high" :src="item?.image1" alt="">
          </div>
          <p class="text-2xl sm:text-xl font-medium text-gray-600">
            {{ item?.text1 }}
          </p>
          <div class="w-full mt-8 px-5 py-3 text-2xl sm:text-xl tracking-wide bg-green-600 hover:bg-green-700 text-white rounded-xl">
            {{ item?.text2 }}
          </div>
        </div>
      </div>
    </div> -->

    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div
          v-for="(item, index) in section3?.list"
          :key="`jYhKya${index}`"
          class="bg-gray-100 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden border"
          @click="navigateTo(localePath(item?.href ?? '/#'))"
        >
          <div class="bg-gray-200">
            <div class="w-full mx-auto">
              <img :src="item?.image1" loading="lazy" alt="" class="h-full w-full object-cover">
            </div>
          </div>

          <div class="text-center bg-gray-100 p-6">
            <p class="text-2xl sm:text-xl font-medium text-gray-600">
              {{ item?.text1 }}
            </p>

            <div class="w-full mt-8 px-5 py-3 text-2xl sm:text-xl tracking-wide bg-green-600 hover:bg-green-700 text-white rounded-xl">
              {{ item?.text2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
