<script setup lang="ts">
const head: any = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

const htmlAttrs = computed(() => head.value.htmlAttrs!)

const route = useRoute()

const refCode: any = useCookie('refCode')

refCode.value = refCode.value || route?.query?.ref
</script>

<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <Body>
      <div class="relative w-full overflow-hidden pb-20">
        <DefaultHeader />

        <div class="relative pt-32 pb-16">
          <slot />
        </div>

        <NewsLetter />

        <DefaultFooter />

        <GroupButtonFloat />

        <CookieSection />
      </div>
    </Body>
  </Html>
</template>
