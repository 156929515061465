<script setup lang="ts">
const localePath = useLocalePath()

const sitedata: any = useSiteData()
</script>

<template>
  <button
    v-if="sitedata?.layout !== 'singlepage' && (_has(sitedata, 'hascart') && sitedata?.hascart)"
    type="button"
    title="Cart"
    class="inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 rounded-full w-12 h-12 bg-gray-800 hover:bg-gray-700 m-0 cursor-pointer bg-none p-0 normal-case leading-5 hover:text-gray-900"
    :style="sitedata?.primary_color ? `background-color: ${sitedata?.primary_color}` : ''"
    @click="navigateTo(localePath('/cart'))"
  >
    <span class="sr-only">Cart</span>
    <svg class="text-white block border-gray-100 align-middle" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312" />
    </svg>
  </button>
</template>
