<script setup lang="ts">
const head: any = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

const htmlAttrs = computed(() => head.value.htmlAttrs!)
</script>

<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <Body>
      <div class="relative w-full overflow-hidden">
        <DefaultHeader />

        <div class="relative pt-6 pb-20">
          <slot />
        </div>

        <NewsLetter />

        <DefaultFooter />

        <CookieSection />
      </div>
    </Body>
  </Html>
</template>
