<script setup lang="ts">
const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section28)
</script>

<template>
  <!-- Start block 28 -->
  <section v-if="data?.status" class="bg-gray-50 text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-16">
      <div class="mb-8 flex flex-wrap justify-between md:mb-16">
        <div class="mb-6 flex w-full flex-col justify-center sm:mb-12 lg:mb-0 lg:w-1/3 lg:pb-24 lg:pt-48">
          <h2 v-if="data?.text1" class="mb-6 text-4xl sm:text-5xl font-bold">
            {{ data?.text1 }}
          </h2>

          <p v-if="data?.text2" class="text-2xl sm:text-xl text-gray-600">
            {{ data?.text2 }}
          </p>
        </div>

        <div class="mb-12 flex w-full md:mb-16 lg:w-2/3">
          <div class="relative left-12 top-12 z-10 -ml-12 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:left-16 md:top-16 lg:ml-0">
            <img fetchpriority="high" :src="data?.image1" loading="lazy" alt="" class="h-full w-full object-cover object-center">
          </div>

          <div class="overflow-hidden rounded-lg bg-gray-100 shadow-lg">
            <img fetchpriority="high" :src="data?.image2" loading="lazy" alt="Photo by Manny Moreno" class="h-full w-full object-cover object-center">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 28 -->
</template>
