<template>
  <Layout5Hero />

  <Layout5Feature />

  <Layout5Feature2 />

  <Layout5Feature3 />

  <Layout5Feature4 />

  <Layout5Feature5 />

  <Layout5Logo />

  <Layout5Contact />
</template>
