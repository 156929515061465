<script setup lang="ts">
const sitedata: any = useSiteData()

const scriptData: any = []

if (sitedata.value?.script_src) {
  scriptData.push({
    async: true,
    src: sitedata.value?.script_src,
    tagPosition: 'head',
  })
}

if (sitedata.value?.script_text) {
  scriptData.push({
    innerHTML: sitedata.value?.script_text,
    tagPosition: 'head',
  })
}

useHead({
  script: scriptData,
})
</script>

<template>
  <header class="w-full flex justify-center print:hidden">
    <nav class="w-full">
      <div class="relative z-30 bg-white">
        <div class="container max-w-screen-2xl m-auto md:px-12 lg:py-0 lg:px-10">
          <div class="flex flex-wrap items-center justify-between py-4 gap-6 lg:gap-0">
            <input id="toggle_nav" type="checkbox" name="toggle_nav" class="peer hidden">
            <div class="w-full px-6 flex justify-between lg:w-max lg:px-0 z-30">
              <HeaderLogo :logo="sitedata?.logo" />

              <div class="flex items-center lg:hidden max-h-10">
                <label id="hamburger" role="button" for="toggle_nav" aria-label="humburger" class="relative p-2">
                  <div
                    id="line"
                    class="m-auto h-0.5 w-6 rounded bg-sky-900 transition duration-300"
                  />
                  <div
                    id="line2"
                    class="m-auto mt-2 h-0.5 w-6 rounded bg-sky-900 transition duration-300"
                  />
                </label>
              </div>
            </div>
            <label
              for="toggle_nav"
              class="hidden peer-checked:block fixed w-full h-full left-0 top-0 z-10 bg-gray-200 bg-opacity-30 backdrop-blur backdrop-filter"
            />
            <HeaderNav />
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
