<script setup lang="ts">
const sitedata: any = useSiteData()

const section7 = computed(() => sitedata.value?.home_data?.section7)
</script>

<template>
  <!-- Start block -->
  <section v-if="section7?.status" class="text-gray-800">
    <div class="container mx-auto max-w-screen-xl px-5 py-24">
      <div class="text-center mb-20">
        <div class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ section7?.text1 }}
        </div>
        <p class="text-2xl sm:text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
          {{ section7?.text2 }}
        </p>
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-500 inline-flex" />
        </div>
      </div>
      <div class="lg:flex lg:flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
        <div
          v-for="(item, index) in section7?.list1"
          :key="`cQzFVD${index}`"
          class="p-4 lg:w-1/3 flex flex-col text-center items-center"
        >
          <div class="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
            <svg class="w-10 h-10" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="flex-grow">
            <div class="text-gray-900 text-3xl sm:text-2xl title-font font-bold mb-3">
              {{ item?.text1 }}
            </div>
            <p class="leading-relaxed text-2xl sm:text-xl">
              {{ item?.text2 }}
            </p>
          </div>
        </div>
      </div>

      <div class="w-full mx-auto text-center mt-10 py-20">
        <div class="mb-6 text-3xl font-bold">
          {{ section7?.text3 }}
        </div>
        <ol class="flex-col xl:flex-row flex items-center justify-center w-full p-3 space-x-2 text-lg font-medium text-center text-gray-500 bg-white rounded-lg sm:p-4 sm:space-x-4 rtl:space-x-reverse">
          <li
            v-for="(item, index) in section7?.list2"
            :key="`ZAJSFy${index}`"
            class="flex items-center text-green-400 mb-3"
          >
            <span class="flex items-center justify-center w-64 h-64 sm:w-48 sm:h-48 p-2 text-2xl sm:text-xl font-bold text-gray-700 border border-8 border-green-400 rounded-full shrink-0">
              {{ item }}
            </span>
            <svg v-if="index < section7?.list2?.length - 1" class="hidden xl:inline w-6 h-6 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
            </svg>
          </li>
        </ol>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
