<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <div class="my-5">
    <ULink
      :to="localePath('/register')"
      active-class="text-blue"
      inactive-class="text-blue-500 hover:text-blue-700"
      class="font-semibold my-5"
    >
      {{ t('register_account') }}
    </ULink> {{ t('if_have_not_account') }}
  </div>
</template>
