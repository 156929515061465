<script lang="ts" setup>
const props = defineProps<{
  data?: any
  type?: any
}>()

const emit = defineEmits(['success'])

const { t, locale } = useI18n()

const authenticated = useAuthenticated()

const host = await useGetHost('host')

const headers: any = { domain: host, locale: locale.value }

const amount = ref()
const loading = ref(false)
const submitStatus = ref(false)

function onSuccess() {
  emit('success')
}

async function onSubmit() {
  if (amount.value && authenticated.value) {
    submitStatus.value = false
    loading.value = true

    await $fetch('/api/invest', {
      method: 'post',
      headers,
      body: {
        type: props?.type,
        amount: amount.value,
        code: props?.data?.id,
      },
    })

    toastAdd({ title: t('register_success') })

    onSuccess()

    loading.value = false
  }

  else {
    submitStatus.value = true
    loading.value = false
  }
}
</script>

<template>
  <UModal :overlay="false">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <div>
            <div class="text-xl uppercase font-bold leading-6 text-gray-900">
              {{ props?.type === 1 ? t('investment') : t('distribution') }}
            </div>
            <div class="text-lg text-gray-900 mt-1">
              {{ props?.data?.name }}
            </div>
          </div>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="onSuccess" />
        </div>
      </template>

      <div class="space-y-2">
        <ContentView
          :content="props?.type === 1 ? props?.data?.text_investment : props?.data?.text_distribution"
          class="text-base tracking-wide article-content"
        />

        <div class="py-3">
          <div class="flex gap-2">
            <UInput
              v-model="amount"
              type="number"
              min="0"
              step="10"
              size="lg"
              :placeholder="t('input_amount')"
              :trailing="false"
              color="white"
              variant="outline"
              autocomplete="false"
              class="w-1/2"
            />
            <UButton
              :loading="loading"
              size="lg"
              block
              class="w-1/2"
              @click="onSubmit"
            >
              {{ t('register') }}
            </UButton>
          </div>
          <div v-if="submitStatus && !amount" class="text-red-500 text-sm p-1">
            {{ `${t('required')} ${t('amount')}` }}
          </div>
        </div>
      </div>

      <div v-if="!authenticated" class="px-3 border rounded-md" :class="submitStatus ? 'border-red-300' : ' border-transparent'">
        <ButtonAccountLogin @click="onSuccess" />

        <ButtonAccountRegister @click="onSuccess" />
      </div>
    </UCard>
  </UModal>
</template>
