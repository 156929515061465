<script setup lang="ts">
const { t, locale } = useI18n()

const sitedata: any = useSiteData()
</script>

<template>
  <footer class="bg-gray-10 text-gray-600 py-8 px-2 font-[sans-serif] print:hidden">
    <div class="container max-w-screen-2xl m-auto">
      <div class="flex flex-col items-center">
        <NuxtLinkLocale v-if="sitedata?.logo && checkImage(sitedata?.logo)" to="/" :hreflang="locale">
          <span class="sr-only">Logo</span>
          <img :src="sitedata?.logo" alt="" class="m-auto w-40 mb-5">
        </NuxtLinkLocale>
        <p v-if="sitedata?.social?.length" class="text-center text-base mb-4">
          {{ t('connected_us') }}
        </p>
        <ul v-if="sitedata?.social" class="flex space-x-4">
          <li
            v-for="(item, index) in sitedata?.social"
            :key="`MzaWlB${index}`"
          >
            <a :href="_get(item, 'uri')" :aria-label="`social-${index}`" target="_blank" class="text-xl hover:text-gray-800">
              <UIcon :name="`i-${_replace(_get(item, 'icon'), ':', '-')}`" dynamic class="w-6 h-6" />
            </a>
          </li>
        </ul>

        <p class="text-center text-base mt-8">
          {{ sitedata?.footer_copyright }}
        </p>
      </div>
    </div>
  </footer>
</template>
