<script setup lang="ts">
const { t, d } = useI18n()
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const section9 = computed(() => sitedata.value?.home_data?.section9)

const items = computed(() => sitedata.value?.blog_lists)
</script>

<template>
  <!-- Start block -->
  <section v-if="items?.length" class="bg-white dark:bg-gray-900">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
        <h2 class="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {{ section9?.text1 ?? 'Blog' }}
        </h2>
        <p class="mb-5 font-light text-gray-800 text-xl dark:text-gray-400">
          {{ section9?.text2 ?? t('blog_text') }}
        </p>
      </div>

      <div class="grid gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8">
        <!-- article - start -->
        <div
          v-for="(item, index) in items"
          :key="`ObAkwu${index}`"
          class="group relative flex h-48 flex-col overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-64 xl:h-96 cursor-pointer"
          @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))"
        >
          <img :src="item?.image" loading="lazy" alt="" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">

          <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent" />

          <div class="relative mt-auto p-4">
            <span class="block text-sm text-gray-200">{{ d(Date.parse(item?.created_at), 'short') }}</span>
            <h2 class="mb-2 text-xl font-semibold text-white transition duration-100">
              {{ item?.name }}
            </h2>

            <span class="font-semibold text-sky-500">{{ t('read_more') }}</span>
          </div>
        </div>
        <!-- article - end -->
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
