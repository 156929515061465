<script setup lang="ts">
const { locale } = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section30)
</script>

<template>
  <!-- Start block 30 -->
  <section v-if="data?.status && data?.list?.length" class="bg-white text-gray-800">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-16">
      <!-- text - start -->
      <div class="mb-10 md:mb-16">
        <div v-if="data?.text1" class="text-center mb-6 text-4xl sm:text-5xl font-bold">
          {{ data?.text1 }}
        </div>

        <p v-if="data?.text2" class="mx-auto max-w-screen-md text-center text-2xl sm:text-xl text-gray-600">
          {{ data?.text2 }}
        </p>
      </div>
      <!-- text - end -->

      <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        <!-- product - start -->
        <div
          v-for="(item, index) in data?.list"
          :key="`nBGcSD${index}`"
        >
          <NuxtLinkLocale
            :to="item?.href ?? '/#'"
            :hreflang="locale"
            class="group relative block h-96 overflow-hidden rounded-t-lg bg-gray-100"
          >
            <img :src="item?.image" loading="lazy" alt="" class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">
            <span v-if="item?.text3" class="absolute left-0 top-3 rounded-r-lg bg-red-500 px-3 py-1.5 text-sm font-semibold uppercase tracking-wider text-white">
              {{ item?.text3 }}
            </span>
          </NuxtLinkLocale>

          <div class="flex items-start justify-between gap-2 rounded-b-lg bg-gray-100 p-4">
            <div class="flex flex-col">
              <NuxtLinkLocale
                v-if="item?.text1"
                :to="item?.href ?? '/#'"
                :hreflang="locale"
                class="font-bold text-gray-800 transition duration-100 hover:text-gray-500 text-2xl"
              >
                {{ item?.text1 }}
              </NuxtLinkLocale>
              <span v-if="item?.text2" class="text-base text-gray-500 lg:text-base">
                {{ item?.text2 }}
              </span>
            </div>

            <div v-if="item?.price1" class="flex flex-col items-end">
              <span class="font-bold text-gray-600 text-xl">{{ item?.price1 }}</span>
              <span v-if="item?.price2" class="text-base text-red-500 line-through">{{ item?.price2 }}</span>
            </div>
          </div>
        </div>
        <!-- product - end -->

        <!-- product - start -->
        <!-- <div>
          <a href="#" class="group relative block h-96 overflow-hidden rounded-t-lg bg-gray-100">
            <img src="https://images.unsplash.com/photo-1523359346063-d879354c0ea5?auto=format&q=75&fit=crop&crop=top&w=600&h=700" loading="lazy" alt="Photo by Nick Karvounis" class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">
          </a>

          <div class="flex items-start justify-between gap-2 rounded-b-lg bg-gray-100 p-4">
            <div class="flex flex-col">
              <a href="#" class="font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-lg">Cool Outfit</a>
              <span class="text-sm text-gray-500 lg:text-base">by Cool Brand</span>
            </div>

            <div class="flex flex-col items-end">
              <span class="font-bold text-gray-600 lg:text-lg">$29.99</span>
            </div>
          </div>
        </div> -->
        <!-- product - end -->

        <!-- product - start -->
        <!-- <div>
          <a href="#" class="group relative block h-96 overflow-hidden rounded-t-lg bg-gray-100">
            <img src="https://images.unsplash.com/photo-1548286978-f218023f8d18?auto=format&q=75&fit=crop&crop=top&w=600&h=700" loading="lazy" alt="Photo by Austin Wade" class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110">
          </a>

          <div class="flex items-start justify-between gap-2 rounded-b-lg bg-gray-100 p-4">
            <div class="flex flex-col">
              <a href="#" class="font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-lg">Nice Outfit</a>
              <span class="text-sm text-gray-500 lg:text-base">by Nice Brand</span>
            </div>

            <div class="flex flex-col items-end">
              <span class="font-bold text-gray-600 lg:text-lg">$35.00</span>
            </div>
          </div>
        </div> -->
        <!-- product - end -->
      </div>
    </div>
  </section>
  <!-- End block 30 -->
</template>
