<script setup lang="ts">
const appConfig = useAppConfig()

const { t, locale }: any = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const url = useRequestURL()

const sitedata: any = useSiteData()

const scriptData: any = []

if (sitedata.value?.script_src) {
  scriptData.push({
    async: true,
    src: sitedata.value?.script_src,
    tagPosition: 'head',
  })
}

if (sitedata.value?.script_text) {
  scriptData.push({
    innerHTML: sitedata.value?.script_text,
    tagPosition: 'head',
  })
}

useHead({
  script: scriptData,
})

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const routeNameCheck = computed(() => _includes(String(route.name), 'index'))

const sitedataMenus = computed(() => sitedata.value?.category?.length ? [...[{ name: t('homepage') }], ...sitedata.value?.category] : [])

const mobileMenu = ref<any>(null)
const mobileMenuIcon1 = ref<any>(null)
const mobileMenuIcon2 = ref<any>(null)
const mobileMenuStatus = ref<any>(false)

function handleMobileMenu() {
  if (!mobileMenuStatus.value) {
    mobileMenu.value.classList.remove('hidden')
    mobileMenuIcon1.value.classList.add('hidden')
    mobileMenuIcon2.value.classList.remove('hidden')
    mobileMenuStatus.value = true
  }
  else {
    mobileMenu.value.classList.add('hidden')
    mobileMenuIcon1.value.classList.remove('hidden')
    mobileMenuIcon2.value.classList.add('hidden')
    mobileMenuStatus.value = false
  }
}

const host = await useGetHost()

const token: any = useCookie('token')

const authenticated = useAuthenticated()

const cartKey = sitedata.value?.site_type === 4 ? 'cart-shop' : 'cart'

const accountItems = ref<any>([])

if (authenticated.value) {
  const items = [{
    label: t('account'),
    icon: 'i-heroicons-user-solid',
    click: async () => {
      await navigateTo(localePath('/account'))
    },
  }]

  if (sitedata.value?.hascart) {
    items.push({
      label: t('orders'),
      icon: 'i-heroicons-shopping-bag-16-solid',
      click: async () => {
        await navigateTo(localePath('/orders'))
      },
    })
  }

  if (sitedata.value?.invest) {
    items.push({
      label: t('investment'),
      icon: 'i-heroicons-currency-dollar',
      click: async () => {
        await navigateTo(localePath('/investments'))
      },
    })
  }

  accountItems.value = [
    ...[items],
    ...[
      [{
        label: t('signout'),
        icon: 'i-heroicons-arrow-left-on-rectangle',
        click: async () => {
          await $fetch('/api/logout', { method: 'post', headers: { domain: host, token: token.value } })
          await Promise.all([
            (token.value = null),
            localStorage.removeItem(cartKey),
            appConfig.locales.forEach((item: any) => { localStorage.removeItem(`${cartKey}-${item}`) }),
          ])
          await navigateTo({ path: localePath('/') }, { external: true })
        },
      }],
    ],
  ]

  // accountItems.value = [
  //   [{
  //     label: t('account'),
  //     icon: 'i-heroicons-user-solid',
  //     click: async () => {
  //       await navigateTo(localePath('/account'))
  //     },
  //   }, {
  //     label: t('orders'),
  //     icon: 'i-heroicons-shopping-bag-16-solid',
  //     click: async () => {
  //       await navigateTo(localePath('/orders'))
  //     },
  //   }],
  //   [{
  //     label: t('signout'),
  //     icon: 'i-heroicons-arrow-left-on-rectangle',
  //     click: async () => {
  //       await $fetch('/api/logout', { method: 'post', headers: { domain: host, token: token.value } })
  //       await Promise.all([
  //         (token.value = null),
  //         localStorage.removeItem(cartKey),
  //         appConfig.locales.forEach((item: any) => { localStorage.removeItem(`${cartKey}-${item}`) }),
  //       ])
  //       await navigateTo({ path: localePath('/') }, { external: true })
  //     },
  //   }],
  // ]
}
else {
  accountItems.value = [
    [{
      label: t('login'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
      },
    }],
    [{
      label: t('register'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo(localePath('/register'))
      },
    }],
  ]
}
</script>

<template>
  <header class="fixed w-full z-[200]">
    <nav class="bg-white border-b border-gray-200 py-2">
      <div class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
        <NuxtLinkLocale v-if="sitedata?.logo && checkImage(sitedata?.logo)" to="/" aria-label="logo" :hreflang="locale" class="flex items-center">
          <span class="sr-only">Logo</span>
          <img :src="sitedata?.logo" alt="" class="h-10 mr-3 sm:h-12 max-w-40">
        </NuxtLinkLocale>
        <div v-else />
        <div class="flex items-center lg:order-2 gap-x-2 sm:gap-x-1">
          <!-- <div class="hidden mt-2 mr-4 sm:inline-block">
            <a class="github-button" href="https://github.com/" data-size="large" data-icon="octicon-star" data-show-count="true" aria-label="Star themesberg/landwind on GitHub">Star</a>
          </div> -->

          <!-- <a href="#" class="text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 focus:outline-none">Log in</a> -->

          <UDropdown
            v-if="sitedata?.login_logout"
            :items="accountItems"
            :ui="{ item: { disabled: 'cursor-text select-text' } }"
            :popper="{ placement: 'bottom-start' }"
            class="mr-1 xl:mr-0.5"
          >
            <a href="#" class="text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 font-medium rounded-lg text-base px-1 lg:px-2 py-2 lg:py-2.5 focus:outline-none">
              {{ authenticated ? t('account') : t('login') }}
            </a>

            <template #account="{ item }">
              <div class="text-left">
                <p>
                  Signed in as
                </p>
                <p class="truncate font-medium text-gray-600">
                  {{ item.label }}
                </p>
              </div>
            </template>

            <template #item="{ item }">
              <span class="truncate text-base">{{ item.label }}</span>

              <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 ms-auto" />
            </template>
          </UDropdown>

          <!-- <a href="#" class="text-white bg-green-500 hover:bg-green-400 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none">Download</a> -->

          <LangCurrency />

          <UButton
            v-if="sitedata?.hascart"
            :title="t('cart')"
            aria-label="cart-button"
            icon="i-heroicons-shopping-cart-solid"
            size="xs"
            class="ml-2"
            :style="`background-color: ${sitedata?.primary_color ?? '#00701a'}`"
            :ui="{ rounded: 'rounded-full' }"
            @click="navigateTo(localePath('/cart'))"
          />

          <button type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:bg-gray-100" @click="handleMobileMenu">
            <span class="sr-only">Open main menu</span>
            <svg ref="mobileMenuIcon1" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" /></svg>
            <svg ref="mobileMenuIcon2" class="hidden w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
          </button>
        </div>
        <div ref="mobileMenu" class="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1">
          <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <li
              v-for="(item, index) in sitedataMenus"
              :key="`sAIUgv${index}`"
            >
              <a
                v-if="item?.type === 'external'"
                :href="item?.url"
                :hreflang="locale"
                target="_blank"
                class="block py-2 pl-3 pr-4 font-bold text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-green-500 lg:p-0"
              >
                {{ item?.name }}
              </a>
              <a
                v-else-if="item?.type === 'internal'"
                :href="localePath(item?.url)"
                :hreflang="locale"
                class="block py-2 pl-3 pr-4 font-bold text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-green-500 lg:p-0"
              >
                {{ item?.name }}
              </a>
              <NuxtLinkLocale
                v-else
                :to="item?.slug ? `/${item?.slug}` : '/'"
                :hreflang="locale"
                class="block py-2 pl-3 pr-4 font-bold text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-green-500 lg:p-0"
                :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'block py-2 pl-3 pr-4 text-white bg-green-500 rounded lg:bg-transparent lg:text-green-500 lg:p-0' : ''"
              >
                {{ item?.name }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
