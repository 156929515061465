export default {
  default: () => import("D:/dev_web/layouts/default.vue"),
  empty: () => import("D:/dev_web/layouts/empty.vue"),
  layout0: () => import("D:/dev_web/layouts/layout0.vue"),
  layout1: () => import("D:/dev_web/layouts/layout1.vue"),
  layout2: () => import("D:/dev_web/layouts/layout2.vue"),
  layout3: () => import("D:/dev_web/layouts/layout3.vue"),
  layout4: () => import("D:/dev_web/layouts/layout4.vue"),
  layout5: () => import("D:/dev_web/layouts/layout5.vue"),
  singlepage: () => import("D:/dev_web/layouts/singlepage.vue")
}