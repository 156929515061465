<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const data = computed(() => sitedata.value?.homepage_section?.section16)

const items = computed(() => sitedata.value?.project_lists)
</script>

<template>
  <!-- Start block 16 -->
  <section v-if="data?.status && items?.length" class="bg-white">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
        <h2 class="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          {{ data?.text1 ?? t('project') }}
        </h2>
        <p class="mb-5 font-light text-gray-800 text-xl dark:text-gray-400">
          {{ data?.text2 }}
        </p>
      </div>

      <div class="font-[sans-serif] py-4 mx-auto lg:max-w-6xl max-w-lg md:max-w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div
            v-for="(item, index) in items"
            :key="`ZNSSZo${index}`"
            class="bg-gray-100 rounded-xl cursor-pointer hover:scale-[1.03] transition-all relative overflow-hidden"
            @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))"
          >
            <div class="p-6 bg-gray-200">
              <div class="w-full h-[220px] overflow-hidden mx-auto aspect-w-16 aspect-h-8">
                <img :src="item?.image" loading="lazy" alt="" class="h-full w-full object-cover">
              </div>
            </div>

            <div class="text-center bg-gray-100 p-6">
              <h3 class="text-lg font-bold text-gray-800 min-h-[3.5rem]">
                {{ item?.name }}
              </h3>

              <button
                type="button"
                class="w-full flex items-center justify-center gap-3 mt-6 px-6 py-3 bg-yellow-400 text-base text-gray-800 font-semibold rounded-xl"
              >
                {{ t('see_detail') }}
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End block 16 -->
</template>
