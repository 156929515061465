<script lang="ts" setup>
const props = defineProps<{
  data?: any
}>()

const emit = defineEmits(['success'])

function onSuccess() {
  emit('success')
}
</script>

<template>
  <UModal :overlay="false">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <div>
            <div v-if="props?.data?.text1" class="text-xl uppercase font-bold leading-6 text-gray-900">
              {{ props?.data?.text1 }}
            </div>
            <div v-if="props?.data?.text2" class="text-lg text-gray-900 mt-1">
              {{ props?.data?.text2 }}
            </div>
          </div>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="onSuccess" />
        </div>
      </template>

      <div class="w-full h-full">
        <img
          v-if="props?.data?.image"
          :src="props?.data?.image"
          loading="lazy"
          alt=""
          class="h-full w-full object-contain object-center"
        >
      </div>
    </UCard>
  </UModal>
</template>
