<script setup lang="ts">
const { t, locale } = useI18n()

const sitedata: any = useSiteData()

const section11 = computed(() => sitedata.value?.home_data?.section11)
const section12 = computed(() => sitedata.value?.home_data?.section12)
</script>

<template>
  <footer class="bg-white dark:bg-gray-800">
    <div class="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
      <div class="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5">
        <div
          v-for="(item, index) in section11"
          :key="`RoDRfL${index}`"
        >
          <h3 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            {{ item?.text1 ?? t('company') }}
          </h3>
          <ul v-if="item?.list1?.length" class="text-gray-500 dark:text-gray-400">
            <li
              v-for="(ite, ind) in item?.list1"
              :key="`xdRRAV${ind}`"
              class="mb-4"
            >
              <NuxtLinkLocale
                :to="ite?.href ?? '/#'"
                :hreflang="locale"
                class=" hover:underline"
              >
                {{ ite?.text }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8">
      <div class="text-center">
        <NuxtLinkLocale
          v-if="sitedata?.logo && checkImage(sitedata?.logo)"
          to="/"
          aria-label="footer-logo"
          :hreflang="locale"
          class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <span class="sr-only">Footer Logo</span>
          <img :src="sitedata?.logo" alt="" class="h-8 mr-3 sm:h-9">
        </NuxtLinkLocale>
        <span class="block text-sm text-center text-gray-500 dark:text-gray-400">
          {{ section12?.copyright ?? '© 2025 PROVIEW. All Rights Reserved. Powered by PROVIEW.' }}
        </span>
        <ul class="flex justify-center mt-5 space-x-5">
          <li>
            <NuxtLinkLocale
              :to="section12?.facebook ?? '/#'"
              :hreflang="locale"
              target="_blank"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <span class="sr-only">facebook</span>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              :to="section12?.instagram ?? '/#'"
              :hreflang="locale"
              target="_blank"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <span class="sr-only">instagram</span>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              :to="section12?.x ?? '/#'"
              :hreflang="locale"
              target="_blank"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <span class="sr-only">x</span>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M18.205 2.25h3.308l-7.227 8.26 8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231 5.45-6.231h.002zm-1.16 17.52h1.832L7.045 4.126H5.078L17.044 19.77z" clip-rule="evenodd" /></svg>
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              :to="section12?.skype ?? '/#'"
              :hreflang="locale"
              target="_blank"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <span class="sr-only">skype</span>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M20.908,13.22C20.962,12.82,21,12.415,21,12c0-4.971-4.029-9-9-9c-0.415,0-0.82,0.038-1.22,0.092 C9.863,2.409,8.731,2,7.5,2C4.462,2,2,4.462,2,7.5c0,1.231,0.409,2.363,1.092,3.28C3.038,11.18,3,11.585,3,12c0,4.971,4.029,9,9,9 c0.415,0,0.82-0.038,1.22-0.092C14.137,21.591,15.269,22,16.5,22c3.038,0,5.5-2.462,5.5-5.5C22,15.269,21.591,14.137,20.908,13.22z M11.979,17c-2.782,0-4.381-1.458-4.381-2.89c0-0.73,0.764-0.963,1.07-0.963c0.707,0,0.942,0.577,1.07,0.841 c0.717,1.476,1.407,1.282,2.087,1.346c0.601,0.057,2.011-0.208,2.011-1.238c0-0.098,0.155-0.768-1.062-1.101 c-0.778-0.212-2.149-0.473-3.278-0.907c-1.062-0.408-1.684-1.25-1.684-2.334C7.812,9.209,8.089,7,11.849,7 c2.508,0,3.846,1.285,3.846,2.416c0,0.26-0.099,0.489-0.298,0.696s-0.761,0.508-1.376,0.115c-0.155-0.099-0.282-0.306-0.436-0.573 c-0.184-0.337-0.486-0.987-1.873-0.987c-1.347,0-1.72,0.643-1.72,0.91c0,0.9,1.718,1.089,2.179,1.208 c1.684,0.434,3.976,0.833,3.976,3.104C16.145,16.497,13.433,17,11.979,17z" clip-rule="evenodd" /></svg>
            </NuxtLinkLocale>
          </li>
          <li>
            <NuxtLinkLocale
              :to="section12?.website ?? '/#'"
              :hreflang="locale"
              target="_blank"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <span class="sr-only">website</span>
              <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
            </NuxtLinkLocale>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
