<script setup lang="ts">
const sitedata: any = useSiteData()

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const scrollTopButton = ref<any>(null)

function handleScroll() {
  if (window.scrollY > 0) {
    scrollTopButton.value.classList.remove('invisible')
  }
  else {
    scrollTopButton.value.classList.add('invisible')
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div
    ref="scrollTopButton"
    class="cursor-pointer invisible items-center justify-center bg-gray-800 hover:bg-gray-700 text-white w-12 h-12 rounded-full transition shadow-md"
    style="display: flex;"
    :style="sitedata?.primary_color ? `background-color: ${sitedata?.primary_color}` : ''"
    @click="scrollToTop"
  >
    <span class="w-3 h-3 border-t border-l border-white rotate-45 mt-[6px]" />
  </div>
</template>
