<script setup lang="ts">
const sitedata: any = useSiteData()

const section8 = computed(() => sitedata.value?.home_data?.section8)
</script>

<template>
  <!-- Start block -->
  <section v-if="section8?.status" class="py-24 text-gray-800">
    <div
      v-for="(item, index) in section8?.list1"
      :key="`vONyHm${index}`"
      class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 pb-24"
    >
      <div class="mb-20 text-center">
        <div class="mb-6 text-4xl sm:text-5xl font-bold">
          {{ item?.text }}
        </div>
      </div>
      <div
        v-if="item?.items?.length"
        class="grid grid-cols-1 gap-8 md:grid-cols-3"
        :class="item?.per_row === 3 ? 'xl:grid-cols-3' : (item?.per_row === 4 ? 'xl:grid-cols-4' : (item?.per_row === 5 ? 'xl:grid-cols-5' : (item?.per_row === 6 ? 'xl:grid-cols-6' : 'xl:grid-cols-3')))"
      >
        <div
          v-for="(ite, ind) in item?.items"
          :key="`kmDcjE${ind}`"
          class="rounded-xl flex justify-center items-center mb-5 w-auto h-50 mx-auto"
        >
          <img :src="ite" alt="" class="object-fit h-50">
        </div>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
